<template>
  <div class='popular-case-container'>
    <div class='search'>
      <!--    年    -->
      <year-select-group :value.sync='searchCondition.year' />
      <!--    月    -->
      <el-date-picker v-model='searchCondition.month' type='month' value-format='MM' format='MM月'
                      placeholder='搜索合作月份' style='width: 100%;' />

      <!--    合作形式    -->
      <el-select v-model='searchCondition.coa_mode' filterable placeholder='搜索合作形式' clearable
                 @change='handleQuery'>
        <el-option v-for='item in options.coa_mode' :key='item.value' :label='item.label' :value='item.value' />
      </el-select>
      <!--    发布平台    -->
      <el-select v-model='searchCondition.platform_name' filterable placeholder='搜索发布平台' clearable
                 @change='handleQuery'>
        <el-option v-for='item in options.platform' :key='item.value' :label='item.label' :value='item.label' />
      </el-select>
      <!--    红人    -->
      <artist-search :artist-id.sync='searchCondition.artist_id' style='width: 100%' @handleSelect='handleQuery' />
      <!--  品类  -->
      <el-select v-model='searchCondition.category' filterable placeholder='搜索品类' clearable
                 @change='handleQuery'>
        <el-option v-for='item in options.category' :key='`category-${item.value}`' :label='item.label'
                   :value='item.label' />
      </el-select>
      <!--  类目  -->
      <el-input v-model='searchCondition.category_sub' placeholder='搜索类目' clearable style='width: 100%'
                @change='handleQuery' />
      <!--    品牌    -->
      <el-input v-model='searchCondition.brand_name' placeholder='搜索品牌' clearable style='width: 100%'
                @change='handleQuery' />
      <!--  品类  -->
      <el-select v-model='searchCondition.special' filterable placeholder='搜索特别场' clearable
                 @change='handleQuery'>
        <el-option v-for='item in options.special' :key='`special-${item.value}`' :label='item.label'
                   :value='item.label' />
      </el-select>
      <div style='text-align: left;grid-column-start:3;grid-column-end:5;'>
        <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading' @click='handleQuery'>
          搜索
        </el-button>
        <el-button icon='el-icon-refresh-right' :disabled='loading' :loading='loading' @click='handleReset'>
          重置
        </el-button>
        <el-button icon='el-icon-delete' type='danger' @click='handleDelete(0,true)'>批量删除</el-button>
      </div>
      <div style='text-align: right;grid-column-start:5;grid-column-end:8;'>
        <el-link type='primary' icon='el-icon-download' @click='handelDownloadTemplate'>
          下载导入模板
        </el-link>
        <popular-case-import v-if='hasAuthority("popular_case_import")' @success='handleQuery'
                             style='margin-right: 10px;' />

        <ml-button v-if='hasAuthority("popular_case_export")' text='导出' icon='el-icon-download' type='' size='mini'
                   @click.native='asyncExportExcel' style='margin-right: 10px;' />

        <popular-case-save v-if='hasAuthority("popular_case_create")' ref='save' @success='handleQuery' />
      </div>
    </div>
    <el-table ref='table' :data='dataList' style='width: 100%' :show-header='false'>
      <el-table-column type='selection' width='50' />
      <el-table-column width='450'>
        <template v-slot='{row}'>
          <div class='basic'>
            <template v-if='Array.isArray(row.pic_posters)&&row.pic_posters.length'>
              <el-image
                v-for='(image,image_index) in row.pic_posters' :key='image_index+"image"'
                :src='image.src' style='width: 60px; height: 90px' fit='contain'
                :preview-src-list='row.pic_posters.map(_=>{return _.src})' />
            </template>
            <div v-else style='color: #aaa'>暂无海报</div>
            <div class='name'>
              <div style='color: #333;font-weight: bold;font-size: 14px;'>
                {{ row.artist_name }}
                <i class='el-icon-connection' />
                {{ row.brand_name }}
                <i class='el-icon-connection' />
                {{ row.coa_date|parseTime('{y}年{m}月') }}
              </div>
              <div style='display: flex;margin: 4px 0;'>
                <img :src='getPlatIcon(row.platform_code)' alt='' style='height: 20px;width: 20px;margin-right: 6px;' />
                {{ row.platform_name }}
                <span v-if='row.update_date' style='color:#ddd;margin-left: 12px;'>数据最后更新：{{ row.update_date
                  }}</span>
              </div>
              <div>
                <span class='ml-tag'>{{ row.coa_mode }}</span>
                <span v-if='row.special' class='ml-tag'>特别场：{{ row.special }}</span>
                <span v-if='row.is_trailer==="Y"' class='ml-tag'>挂车</span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column width='200'>
        <template v-slot='{row}'>
          <div style='color: #333;font-weight: bold;font-size: 14px;'>{{ row.product_name }}</div>
          <div v-if='row.efficacy' style='margin: 3px 0;'>功效：{{ row.efficacy }}</div>
          <span class='ml-tag'>
            {{ row.category || '' }}{{ row.category_sub ? `/${row.category_sub}` : row.category_sub || '' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column>
        <template v-slot='{row}'>
          <div style='color: #333;font-weight: bold;font-size: 14px;'>{{ row.price_gmv|numberFormat(2) }} 万</div>
          <div>GMV</div>
        </template>
      </el-table-column>
      <el-table-column>
        <template v-slot='{row}'>
          <div style='color: #333;font-weight: bold;font-size: 14px;'>￥{{ row.price_per_customer|numberFormat(2) }}
          </div>
          <div>客单价</div>
        </template>
      </el-table-column>
      <el-table-column>
        <template v-slot='{row}'>
          <div style='color: #333;font-weight: bold;font-size: 14px;'>{{ row.num_sales|numberFormat }} 件</div>
          <div>销量</div>
        </template>
      </el-table-column>
      <el-table-column>
        <template v-slot='{row}'>
          <div style='color: #333;font-weight: bold;font-size: 14px;'>{{ row.num_live_visitors|numberFormat }} 人</div>
          <div>直播在线人数</div>
        </template>
      </el-table-column>
      <el-table-column width='160'>
        <template v-slot='{row}'>
          <el-button v-if='hasAuthority("popular_case_edit")' plain round @click='handleEdit(row)' size='mini'>
            修改
          </el-button>
          <el-button v-if='hasAuthority("popular_case_delete")' plain round @click='handleDelete(row.id)' size='mini'>删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style='display: flex;justify-content: space-between;align-items: center;margin-top: 12px;'>
      <div>
        <el-link v-if='hasAuthority("popular_cases_category_list")'
                 type='primary' icon='el-icon-s-grid' href='/kol/KoLPopularCaseCategory' target='_blank'
                 style='margin-left: 10px;'>分类类目管理
        </el-link>
        <el-link
          v-if='hasAuthority("popular_cases_category_board")'
          type='primary' icon='el-icon-s-data' href='/kol/KoLPopularCaseCategoryBoard' target='_blank'
          style='margin-left: 10px;'>品类看板
        </el-link>
      </div>
      <el-pagination background :page-size='pageData.page_size' :current-page.sync='pageData.current_page'
                     layout='prev, pager, next,total' :total='pageData.total' />
    </div>
    <div style='height:12px;' />
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import MlPageTable from '@/components/common/MlPageTable'
import ExportBtn from '@/components/export/ExportBtn'
import {
  deletePopularCase, exportAsyncPopularCases,
  exportPopularCases,
  getPopularCaseList,
  optionsPopularCases
} from '../../../api/popular_case'
import PopularCaseSave from './save.vue'
import YearSelectGroup from '../../../components/SelectGroupYear.vue'
import ArtistSearch from '../../../components/artist/ArtistSearch.vue'
import moment from 'moment/moment'
import PopularCaseImport from './import.vue'
import MlButton from '@/components/MlButton/index.vue'

let platforms = localStorage.getItem('platforms')
platforms = platforms ? JSON.parse(platforms) : []

const ModelSearch = {
  year: moment().format('YYYY'),
  month: null,
  artist_id: null,
  platform_name: null,
  coa_mode: null,
  brand_name: null,
  category: null,
  special: null,
  category_sub: null
}
const pageSearchKey = 'page_search_key:popular_case'
export default {
  name: 'PopularCaseList',
  components: { MlButton, PopularCaseImport, ArtistSearch, YearSelectGroup, PopularCaseSave, ExportBtn, MlPageTable },
  data() {
    return {
      loading: false,
      dataList: [],
      options: { platform: [], coa_mode: [], category: [], special: [] },
      searchCondition: { ...{}, ...ModelSearch },
      pageData: { current_page: 1, page_size: 20, total: 0 }
    }
  },
  watch: {
    'searchCondition.year'() {
      this.handleQuery()
    },
    'pageData.current_page'(_) {
      this.pageData.current_page = _
      this.getList()
    }
  },
  created() {
    this.getOptions()
    this.handleQuery()
  },
  methods: {
    getPlatIcon: function(code) {
      const obj = platforms.find(_ => {
        return _.code === code
      })
      return obj ? obj.logo_url : ''
    },
    getOptions: async function() {
      this.options = await optionsPopularCases({ fields: ['coa_mode', 'platform', 'category', 'special'] })
    },
    handleReset: function() {
      this.searchCondition = { ...{}, ...ModelSearch }
      this.handleQuery()
    },
    handleQuery: function() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(pageSearchKey, JSON.stringify(this.searchCondition))
    },
    handleSearchCondition() {
      let condition = { ...{}, ...this.searchCondition }
      Object.assign(condition, this.pageData, { orderBy: { id: 'desc' } })
      return condition
    },
    async getList() {
      if (this.loading) {
        return
      }
      let params = this.handleSearchCondition()
      this.loading = true
      let { list, pages } = await getPopularCaseList(params)
      this.loading = false
      this.dataList = list
      this.pageData = pages
    },
    /** 删除按钮 */
    handleDelete(id, is_batch = false) {
      let ids = [id]
      if (is_batch) {
        // 批量删除
        const selection = this.$refs.table.selection
        if (selection.length < 1) {
          this.$message.warning('请选择要删除的行数据')
          return
        }
        ids = selection.map(_ => {
          return _.id
        })
      }
      this.$confirm('此操作将删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deletePopularCase(ids)
        await this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async handleEdit(row) {
      this.$refs.save.interfaceEdit(row)
    },

    //导出表格
    async handleExport() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await exportPopularCases(searchCondition)
        let name = `爆款案例`

        this.$refs.export.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        this.$refs.export.hideLoading()
      }
    },
    async asyncExportExcel() {
      let searchCondition = this.handleSearchCondition()
      let { task_id } = await exportAsyncPopularCases(searchCondition)
      // this.$notify.warning(task_id)
      let msg = '<strong>点击这里 <a href=\'/download_file\' target=\'_blank\'>下载</a> 生成的文件:' + task_id + '</strong>'
      this.$notify({
        title: '导出文件生成中',
        dangerouslyUseHTMLString: true,
        message: msg,
        type: 'success',
        duration: 0
      })
      this.exportLoading = false
    },
    async handelDownloadTemplate() {
      try {
        const isFileSaverSupported = !!new Blob
        if (isFileSaverSupported) {
          let response = await this.$api.downLoadFile({}, '/popular_case/down_template')
          let data = response.data
          let contentType = response.headers['content-type']
          let blob = new Blob([data], { type: contentType })

          FileSaver.saveAs(blob, decodeURI(name + '爆款案例.xlsx'), { autoBom: true })
        } else {
          this.$message.warning('当前浏览器不支持下载文件')
        }
      } catch (e) {
        this.$message.warning('下载异常，请联系管理员')
      }
    }
  }
}
</script>

<style lang='scss'>
.el-main {
  //margin: 12px 12px 0 12px;
}

.KoLPopularCase_main-ape {
  height: 100%;
}

.KoLPopularCase_main-inner {
  height: calc(100% - 12px);

  &__content {
    height: calc(100vh - 90px);
  }

  .page-content {
    min-height: unset;
    height: calc(100% - 38px);
    background: #fff;

    .main-page-content {
      min-height: unset;
      height: 100%;
      padding: 0 !important;
    }
  }

  .popular-case-container {
    padding: 20px;
    height: calc(100% - 102px);

    .search {
      display: grid;
      grid-template-columns: repeat(7, calc((100% - 60px) / 7));
      grid-gap: 10px;
      margin-bottom: 20px;

      .el-autocomplete {
        width: 100%;
      }
    }

    .ml-page-table {
      height: calc(100% - 82px);

      .default-table {
        height: calc(100% - 46px);

      }
    }
  }

  .basic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;

    .name {
      width: calc(100% - 80px);
    }
  }

  .ml-tag {
    border-radius: 4px;
    background: rgba(255, 49, 118, 0.1);
    color: rgb(255, 49, 118);
    padding: 4px 12px;
    font-size: 12px;
  }

  .ml-tag + .ml-tag {
    margin-left: 10px;
  }
}
</style>
